import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import SearchLink from "./SearchLink"
import TopSecondaryMenu from "./TopSecondaryMenu"
import MainMenu from "./MainMenu"
import MobileMenuToggle from "../UI/MobileMenuToggle"
import logo from "../../assets/awis-logo.png"

const NavMain = ({ toggleSidebar }) => {
  return (
    <Wrapper>
      <nav className="navbar">
        <div className="nav-center">
          <div className="nav-header">
            <Link to="/">
              <img
                className="logo"
                src={logo}
                alt="logo"
                width="100"
                height="100"
              />
            </Link>
            <SearchLink styleClass="button search-link-mobile" />
            <MobileMenuToggle toggleSidebar={toggleSidebar} />
          </div>
          <div className="top-menus">
            <TopSecondaryMenu styleClass="nav-links secondary-links" />
            <MainMenu styleClass="nav-links" />
          </div>
        </div>
      </nav>
    </Wrapper>
  )
}

export default NavMain

const Wrapper = styled.div`
  .navbar {
    width: 100%;
    height: 8.5rem;
    display: flex;
    align-items: center;
    z-index: 200;
    background: var(--clr-white);
    box-shadow: var(--light-shadow);
  }
  .nav-center {
    width: 95vw;
    max-width: 100%;
    margin: 0 auto;
    z-index: 100;
  }
  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .nav-header img {
    margin-bottom: 0;
  }
  .top-menus {
    line-height: 3rem;
  }
  .nav-links {
    display: none;
  }
  .logo {
    height: 7rem;
    width: auto;
    /* padding-left: 0.8rem; */
  }

  /* Media Queries */
  @media screen and (min-width: 760px) {
    .nav-links {
      display: flex;
      justify-content: flex-end;
    }
    .nav-links li {
      margin-right: 3.2rem;
    }
    .nav-links a {
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: var(--spacing);
      transition: var(--transition);
      padding: 0.8rem;
    }
    .secondary-links a {
      text-transform: capitalize;
      font-weight: normal;
      font-size: 1.4rem;
      letter-spacing: calc(var(--spacing) / 2);
      transition: var(--transition);
      padding: 0.8rem;
    }
    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
    }
    .search-link-mobile {
      display: none;
    }
  }

  @media print {
    .search-link-mobile {
      display: none;
    }
  }

  @media screen and (min-width: 1200px) {
    .logo {
      padding-left: 2rem;
    }
  }
`
