const company = {
  fullName: "AWIS - Al Wataniya International School",
  box: "P.O.Box 22698",
  street: "Dohat Al Khaima Street",
  city: "Doha",
  country: "Qatar",
  phone: "+974 4017-4930",
  email: "info@awisdoha.com",
  google: "https://g.page/r/CTvNbPTNgerNEAE",
  openingHours: "Su,Mo,Tu,We,Th 07:00-14:00",
}

export default company
