import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import ExpandableLink from "./ExpandableLink"

export const query = graphql`
  {
    allStrapiMainMenu(sort: { fields: sortOrder }) {
      nodes {
        id
        label
        url
        subLinks {
          id
          label
          url
        }
      }
    }
  }
`

const DropdownMenu = ({ styleClass, toggleDropdown }) => {
  const {
    allStrapiMainMenu: { nodes: navData },
  } = useStaticQuery(query)

  const linkList = navData.map(link => {
    return (
      <ExpandableLink
        link={link}
        key={link.id}
        toggleDropdown={toggleDropdown}
      />
    )
  })

  return (
    <Wrapper>
      <nav>
        <ul className={`${styleClass ? styleClass : ""}`}>{linkList}</ul>
      </nav>
    </Wrapper>
  )
}

export default DropdownMenu

const Wrapper = styled.div`
  .container {
    padding: 1rem 2rem;
    visibility: hidden;
    opacity: 0;
    display: none;
    width: 75%;
    max-width: calc(var(--max-width) * 0.75);
    position: absolute;
    left: 60%;
    transform: translateX(-50%);
    z-index: 100;
    background: var(--clr-grey-7);
    border-radius: var(--radius);
    box-shadow: var(--light-shadow);
    transition: var(--transition);
  }
  .container:hover {
    visibility: visible;
    opacity: 0.95;
    display: visible;
  }

  li:hover > section.container,
  :focus-within > section.container {
    visibility: visible;
    opacity: 0.95;
    display: block;
    color: var(--clr-grey-1);
    font-size: 1.6rem;
  }
  .title {
    background-color: var(--clr-primary-5);
    letter-spacing: var(--spacing);
    padding: 0.5rem 2rem;
    border-bottom: 1px solid var(--clr-primary-1);
  }
  .title a {
    display: block;
  }
  .content {
    padding-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
  }
  .image-container {
    width: 20vw;
  }
  .image {
    border-radius: var(--radius);
    height: 20rem;
  }

  .links-container,
  .text-container {
    width: 30vw;
    padding-left: 4rem;
  }

  li.sub-link {
    clear: both;
    width: 100%;
  }
  li.sub-link a {
    color: var(--clr-primary-1);
    font-size: 1rem;
    text-transform: capitalize;
    font-weight: 200;
    display: block;
  }
  li.sub-link a:hover {
    text-decoration: underline;
  }
`
