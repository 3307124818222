import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import TopSecondaryMenu from "./TopSecondaryMenu"
import DropdownMenu from "./DropdownMenu"
import company from "../../constants/company"

const MobileMenu = ({ isOpen, toggleSidebar, toggleDropdown }) => {
  return (
    <Wrapper>
      <aside className={`${isOpen ? "show-sidebar" : ""} sidebar`}>
        <div className="side-container">
          <TopSecondaryMenu styleClass="secondary-links" />
          <DropdownMenu
            toggleDropdown={toggleDropdown}
            styleClass="sidebar-links"
          />
          <div className="cta-buttons">
            <a
              className="btn"
              href={company.google}
              rel="noreferrer"
              target="_blank"
            >
              Directions
            </a>
            <a href={`tel:${company.phone}`} className="btn">
              Call now
            </a>
            <Link to="/apply/register-now" className="btn">
              Apply
            </Link>
          </div>
        </div>
      </aside>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  .sidebar {
    background: var(--clr-primary-1);
    position: absolute;
    top: 7.5rem;
    right: 0;
    width: 90%;
    min-height: 100vh;
    z-index: 999;
    border-top: 3px solid var(--clr-primary-3);
    opacity: 0;
    visibility: hidden;
    display: none;
    transition: var(--transition);
    transform: translateX(100%);
  }
  .show-sidebar {
    opacity: 0.95;
    visibility: visible;
    display: block;
    transform: translateX(0);
  }
  .side-container {
    width: 95%;
    margin: 0 auto;
  }
  .secondary-links {
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 2rem 0 3rem 0;
  }
  .secondary-links a {
    color: var(--clr-white);
    text-transform: uppercase;
    text-align: center;
    font-weight: normal;
    font-size: 1.4rem;
    letter-spacing: calc(var(--spacing) / 2);
    transition: var(--transition);
    padding: 0.8rem;
  }
  .sidebar-links li.top-link {
    border-bottom: 1px solid var(--clr-primary-3);
  }
  .sidebar-links li a {
    display: block;
    text-align: left;
    text-transform: capitalize;
    color: var(--clr-white);
    letter-spacing: var(--spacing);
    font-size: 3.2rem;
    padding-left: 1rem;
  }
  .sidebar-links li.sub-link a {
    font-size: 2rem;
    border: none;
    padding: 0.8rem;
    padding-left: 1.5rem;
  }
  .sidebar-links li a:hover {
    background: var(--clr-primary-9);
    color: var(--clr-primary-4);
  }
  .cta-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
  }
  .btn {
    color: var(--clr-white);
    font-size: 1.8rem;
    text-align: center;
    align-self: center;
    border: 1px solid var(--clr-primary-3);
    border-radius: var(--radius);
    padding: 0.8rem 1.2rem;
    margin: 5rem 0;
    width: 100%;
  }
  @media screen and (min-width: 760px) {
    .sidebar {
      display: none;
    }
  }
`

export default MobileMenu
