import React from "react"
import styled from "styled-components"
import {
  FaFacebookSquare,
  // FaTwitterSquare,
  FaInstagramSquare,
  FaPhoneSquare,
} from "react-icons/fa"
import company from "../constants/company"

const socialData = [
  {
    id: 1,
    icon: <FaFacebookSquare className="social-icon"></FaFacebookSquare>,
    url: "https://www.facebook.com/AWISDoha",
  },
  // {
  //   id: 2,
  //   icon: <FaTwitterSquare className="social-icon"></FaTwitterSquare>,
  //   url: "https://www.twitter.com/AWISDoha",
  // },
  {
    id: 3,
    icon: <FaInstagramSquare className="social-icon"></FaInstagramSquare>,
    url: "https://www.instagram.com/AWISDoha",
  },
  // {
  //   id: 4,
  //   icon: <FaEmailSquare className="social-icon"></FaEmailSquare>,
  //   // url: "mailto:info@awisdoha.com",
  //   url: "https://www.instagram,"
  // },
  {
    id: 5,
    icon: <FaPhoneSquare className="social-icon"></FaPhoneSquare>,
    url: `tel:${company.phone}`,
  },
]
const links = socialData.map(link => {
  return (
    <li key={link.id}>
      <a
        href={link.url}
        className="social-link"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        {link.icon}
      </a>
    </li>
  )
})

const socialLinks = ({ styleClass }) => {
  return (
    <Wrapper>
      <ul className={`social-links ${styleClass ? styleClass : ""}`}>
        {links}
      </ul>
    </Wrapper>
  )
}

export default socialLinks

const Wrapper = styled.div`
  .social-links {
    margin-top: 3.2rem;
    max-width: 18rem;
    display: flex;
    justify-content: space-between;
  }
  .social-link {
    font-size: 2.8rem;
    color: var(--clr-grey-3);
    transition: var(--transition);
    padding: 1rem;
  }
  .social-link:hover {
    color: var(--clr-primary-3);
  }
`
