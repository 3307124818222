import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import NavLink from "./NavLink"
import SearchLink from "./SearchLink"

export const query = graphql`
  {
    strapiTopRightMenu {
      link {
        id
        label
        url
      }
    }
  }
`

const TopSecondaryMenu = ({ styleClass }) => {
  const data = useStaticQuery(query)
  const {
    strapiTopRightMenu: { link: navData },
  } = data

  const linkList = navData.map(link => {
    return (
      <li key={link.id}>
        <NavLink to={link.url}>{link.label}</NavLink>
      </li>
    )
  })

  return (
    <ul className={`${styleClass ? styleClass : ""}`}>
      <SearchLink />
      {linkList}
    </ul>
  )
}

export default TopSecondaryMenu
