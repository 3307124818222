import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import "../css/main.css"
import HeaderMenus from "./navigation/HeaderMenus"
import MobileMenu from "./navigation/MobileMenu"
import Footer from "./Footer"

const Layout = ({ children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false)

  const toggleSidebar = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  return (
    <Wrapper>
      <GlobalStyle />
      <div className="page-container">
        <HeaderMenus toggleSidebar={toggleSidebar} />
        <MobileMenu isOpen={isMobileMenuOpen} toggleSidebar={toggleSidebar} />
        {children}
        <Footer />
      </div>
    </Wrapper>
  )
}

export default Layout

const Wrapper = styled.div`
  .page-container {
    width: 100%;
    margin: 0 auto;
    max-width: var(--max-width);
    min-height: 80vh;
  }
`

const GlobalStyle = createGlobalStyle`
	:root {
		--bp-small: 576px;
		--bp-tablet: 760px;
		--bp-desktop: 1000px;
		--max-width: 1200px;
	}
`
