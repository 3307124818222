import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import NavLink from "./NavLink"

export const query = graphql`
  {
    allStrapiMainMenu(sort: { fields: sortOrder }) {
      nodes {
        id
        label
        url
        subLinks {
          id
          label
          url
        }
      }
    }
  }
`

const MainMenu = ({ styleClass }) => {
  const {
    allStrapiMainMenu: { nodes: navData },
  } = useStaticQuery(query)

  const linkList = navData.map(link => {
    return (
      <Wrapper key={link.id}>
        <li>
          <NavLink to={link.url} aria-haspopup={false}>
            {link.label}
          </NavLink>
        </li>
      </Wrapper>
    )
  })

  return <ul className={`${styleClass ? styleClass : ""}`}>{linkList}</ul>
}

export default MainMenu

const Wrapper = styled.ul``
