import React from "react"
import styled from "styled-components"
import NavLink from "./NavLink"
import { FaSearch } from "react-icons/fa"

const SearchLink = ({ styleClass }) => {
  return (
    <Wrapper>
      <NavLink to="/search" className={styleClass}>
        <FaSearch className="search-icon" />
        Search
      </NavLink>
    </Wrapper>
  )
}

export default SearchLink

const Wrapper = styled.li`
  list-style: none;
  .search-icon {
    margin-right: 0.5rem;
  }
`
