import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const ExpandableLink = props => {
  const [urlOfOpenDropdown, setUrlOfOpenDropdown] = React.useState(null)

  const handleDropdownClick = () => {
    setUrlOfOpenDropdown(
      urlOfOpenDropdown === props.link.url ? null : props.link.url
    )
  }

  const isActive = ({ isCurrent }) => {
    const propsClasses = props.className ? props.className : ""
    return {
      className: isCurrent
        ? "active " + propsClasses
        : "navlink " + propsClasses,
    }
    // Note active class defined in global css
  }

  const hasSublinks = props.link.subLinks && props.link.subLinks.length > 0
  let classTopLink = "top-link"
  if (hasSublinks) {
    classTopLink += " has-expander"
  }
  if (props.className) {
    classTopLink += " " + props.className
  }

  let btnClasses = "expander"
  if (urlOfOpenDropdown === props.link.url) {
    btnClasses += " open"
  } else {
    btnClasses += " closed"
  }
  if (!hasSublinks) {
    btnClasses += " hidden"
  }

  return (
    <Wrapper className={classTopLink}>
      <span className="top-menu">
        <Link
          to={props.link.url}
          aria-haspopup={
            props.link.subLinks && props.link.subLinks.length > 0
              ? "true"
              : "false"
          }
          getProps={isActive}
          className={classTopLink}
        >
          {props.link.label}
        </Link>
        <button className={btnClasses} onClick={handleDropdownClick}>
          {" "}
        </button>
      </span>

      <div
        className={
          urlOfOpenDropdown === props.link.url
            ? "dropdown open"
            : "dropdown collapsed"
        }
      >
        <ul aria-label="subLinks">
          {props.link.subLinks.map(subLink => (
            <li className="sub-link" key={subLink.id}>
              <a href={subLink.url}>{subLink.label}</a>
            </li>
          ))}
        </ul>
      </div>
    </Wrapper>
  )
}

export default ExpandableLink

const Wrapper = styled.li`
  .top-menu {
    display: block;
    width: 100%;
    position: relative;
  }
  button.expander {
    position: absolute;
    top: 0;
    right: 0;
    width: 6rem;
    border: none;
    background-color: var(--clr-primary-2);
    border-radius: var(--radius);
    margin: 0.5rem 0;
    cursor: pointer;
    outline: none;
    font-size: 3rem;
    font-weight: bold;
    color: var(--clr-white);
  }
  button.hidden {
    display: none;
  }
  button.closed::before {
    content: "+";
  }
  button.open::before {
    content: "-";
  }
  .dropdown {
    padding-left: 0.5rem;
    transition: all 0.3s ease;
  }
  .open {
    height: auto;
    max-height: 9999px;
    opacity: 1;
    visibility: visible;
    padding-bottom: 1.5rem;
  }
  .collapsed {
    max-height: 0;
    visibility: hidden;
    opacity: 0;
    padding-bottom: 0;
  }
`
