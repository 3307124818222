import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import company from "../constants/company"

const Map = ({ styleClass }) => {
  return (
    <Wrapper>
      <a href={company.google} rel="noreferrer" target="_blank">
        <StaticImage
          src="../assets/map.jpg"
          alt="location map"
          className="map-img"
        />
      </a>
    </Wrapper>
  )
}

export default Map

const Wrapper = styled.div`
  & {
    text-align: center;
  }
  .map-img {
    border-radius: var(--radius);
    margin-bottom: 2rem;
  }
`
