import React from "react"
import styled from "styled-components"

function MobileMenuToggle({ toggleSidebar }) {
  return (
    <Wrapper>
      <input
        type="checkbox"
        className="navigation__checkbox"
        id="navi-toggle"
        onChange={toggleSidebar}
      />

      <label htmlFor="navi-toggle" className="navigation__button">
        <span className="navigation__icon">&nbsp;</span>
      </label>
    </Wrapper>
  )
}

export default MobileMenuToggle

const Wrapper = styled.div`
  & {
    width: 7rem;
    min-height: 100%;
    position: relative;
  }

  .navigation__checkbox {
    display: none;
  }

  .navigation__button {
    background-color: var(--clr-primary-1);
    height: 5.5rem;
    width: 6rem;
    position: absolute;
    top: -2rem;
    right: 0;
    border-radius: var(--radius-large);
    /* z-index: 2000; */
    box-shadow: var(--light-shadow);
    text-align: center;
    cursor: pointer;
  }

  //FUNCTIONALITY
  .navigation__checkbox:checked ~ .navigation__background {
    transform: scale(80);
  }

  .navigation__checkbox:checked ~ .navigation__nav {
    opacity: 1;
    width: 100%;
  }

  //ICON
  .navigation__icon {
    position: relative;
    margin-top: 2.5rem;
  }
  .navigation__icon,
  .navigation__icon::before,
  .navigation__icon::after {
    width: 3rem;
    height: 2px;
    background-color: white;
    display: inline-block;
  }

  .navigation__icon::before,
  .navigation__icon::after {
    content: "";
    position: absolute;
    left: 0;
    transition: all 0.2s;
  }

  .navigation__icon::before {
    top: -1rem;
  }
  .navigation__icon::after {
    top: 1rem;
  }

  .navigation__button:hover .navigation__icon::before {
    top: -1.1rem;
  }

  .navigation__button:hover .navigation__icon::after {
    top: 1.1rem;
  }

  .navigation__checkbox:checked + .navigation__button .navigation__icon {
    background-color: transparent;
  }

  .navigation__checkbox:checked
    + .navigation__button
    .navigation__icon::before {
    top: 0;
    transform: rotate(135deg);
  }

  .navigation__checkbox:checked + .navigation__button .navigation__icon::after {
    top: 0;
    transform: rotate(-135deg);
  }

  /* Media Queries */
  @media screen and (min-width: 760px) {
    & {
      display: none;
    }
  }
`
