import React from "react"
import { Link } from "gatsby"

const NavLink = props => {
  const isActive = ({ isCurrent }) => {
    const propsClasses = props.className ? props.className : ""
    return {
      className: isCurrent
        ? "active " + propsClasses
        : "navlink " + propsClasses,
    }
    // Note active class defined in global css
  }

  return <Link getProps={isActive} {...props} />
}

export default NavLink
