import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import NavLink from "./navigation/NavLink"

export const query = graphql`
  {
    strapiUsefulLink {
      link {
        id
        label
        url
      }
    }
  }
`

const UsefulLinks = ({ styleClass }) => {
  const data = useStaticQuery(query)
  const {
    strapiUsefulLink: { link: links },
  } = data

  const linkList = links.map(link => {
    return (
      <li key={link.id}>
        <NavLink to={link.url}>{link.label}</NavLink>
      </li>
    )
  })

  return (
    <Wrapper>
      <ul className={`${styleClass ? styleClass : ""}`}>{linkList}</ul>
    </Wrapper>
  )
}

export default UsefulLinks

const Wrapper = styled.nav`
  .link-text {
    font-weight: normal;
  }
  .link {
    margin-bottom: 1rem;
  }
`
