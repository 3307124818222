import React from "react"
import styled from "styled-components"
import SocialLinks from "../constants/socialLinks"
import FooterMenu from "./navigation/FooterMenu"
import UsefulLinks from "./UsefulLinks"
import logo from "../assets/awis-logo.png"
// import UsefulLinks from "../constants/usefulLinks"
import Address from "./Address"
import Map from "./Map"
import company from "../constants/company"

const Footer = () => {
  return (
    <Wrapper>
      <footer className="footer">
        <div className="branding">
          <img className="logo" src={logo} alt="logo" width="50" height="50" />
          <h4>AL WATANIYA</h4>
          <div className="subtitle">International School</div>
        </div>
        <div className="footer-blocks">
          <Map />
          <div className="address">
            <h5>Address</h5>
            <Address />
          </div>
          <div className="useful">
            <h5>Useful Links</h5>
            <UsefulLinks styleClass="useful-links" />
          </div>
          <div className="connect">
            <h5>Connect With Us</h5>
            <SocialLinks styleClass="footer-links" />
          </div>
        </div>
        <FooterMenu styleClass="footer-nav" />
        <div className="company">{company.fullName}</div>
      </footer>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  .branding {
    position: relative;
    top: -25px;
  }
  .subtitle {
    color: var(--clr-primary-4);
  }
  .footer-blocks {
    width: 70%;
    margin: 0 auto;
  }
  .footer {
    background: var(--clr-primary-1);
    text-align: center;
    margin-top: 3rem;
  }
  .footer h4 {
    font-size: 1.88rem;
    margin-top: 0.8rem;
    margin-bottom: 0;
    color: var(--clr-white);
    font-weight: normal;
    text-transform: uppercase;
  }
  .footer h5 {
    color: var(--clr-white);
    font-weight: normal;
    font-size: 1.6rem;
    border-bottom: 1px solid var(--clr-primary-2);
    margin-bottom: 1rem;
  }
  .footer-links {
    margin: 0 auto 1.6rem auto;
    display: flex;
    justify-content: space-between;
  }
  .footer .social-link {
    color: var(--clr-white);
  }
  .footer .navlink:hover,
  .footer .social-link:hover {
    color: var(--clr-primary-4);
  }
  .footer a {
    color: var(--clr-white);
  }
  .footer a.active {
    border-bottom: 1px solid var(--clr-white);
    background: transparent;
  }
  .footer-nav {
    margin: 4rem auto;
    font-weight: normal;
    width: 24rem;
    display: flex;
    justify-content: space-between;
  }
  .company {
    color: var(--clr-primary-4);
    padding: 1rem 0;
  }
  .useful,
  .address,
  .map,
  h5 {
    display: none;
  }

  @media screen and (min-width: 760px) {
    .useful,
    .address,
    .map,
    h5 {
      display: block;
    }
    .phone {
      margin-top: 1rem;
    }
    .footer-blocks {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: 0 auto;
      text-align: left;
      color: var(--clr-white);
    }
    .map-img {
      width: 25rem;
      height: 21rem;
    }
    .footer-nav {
      margin-right: 3rem;
    }
    .logo {
      width: 80px;
      height: 80px;
    }
    .branding {
      top: -40px;
    }
  }
`

export default Footer
