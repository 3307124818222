import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import NavLink from './NavLink';

export const query = graphql`
	{
		strapiFooterMenu {
			link {
				id
				label
				url
			}
		}
	}
`;

const FooterMenu = ({ styleClass }) => {
	const data = useStaticQuery(query);
	const {
		strapiFooterMenu: { link: navLinks },
	} = data;

	const linkList = navLinks.map((link) => {
		return (
			<li key={link.id}>
				<NavLink to={link.url}>{link.label}</NavLink>
			</li>
		);
	});

	return <ul className={`${styleClass ? styleClass : ''}`}>{linkList}</ul>;
};

export default FooterMenu;
