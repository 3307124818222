import React from "react"
import styled from "styled-components"
import company from "../constants/company"

const Address = ({ styleClass }) => {
  return (
    <Wrapper>
      <div>{company.box}</div>
      <div>{company.street}</div>
      <div>{company.city}</div>
      <div>{company.country}</div>
      <div className="phone">{company.phone}</div>
    </Wrapper>
  )
}

export default Address

const Wrapper = styled.address`
  & {
    font-style: normal;
  }
  .phone {
    margin-top: 1rem;
  }
`
